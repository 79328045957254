#scroll-text {
  animation: scroll 8s ease-in-out infinite;
}

@keyframes scroll {
  0% {
    left: 0;
  }
  30% {
    left: calc(
      150px - var(--text-width) - 25px
    ); /* Moves left until last character is visible */
  }
  40% {
    left: calc(150px - var(--text-width) - 25px);
  }
  70% {
    left: 0px;
  }
  80% {
    left: 0px;
  }
}
/* Adding fade effects at the edges */
#scroll-container::before,
#scroll-container::after {
  content: "";
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

#scroll-container::after {
  right: 0;
  background: linear-gradient(to left, #000000, rgba(0, 0, 0, 0));
}

.expanded-text::after{
  content: none !important
}